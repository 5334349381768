import React from 'react'
import Helmet from 'react-helmet'
import { Layout, Heading, Row, Column, Image, Section, Text } from 'components'
import Blog from 'images/blogs/blog-9.png'
import 'components/css/stories.css'

const SITE_META = [
  {
    name: 'description',
    content: 'Mabilis, Madali, Mura',
  },
  {
    name: 'keywords',
    content:
      'digipay, fintech, e-payments, bills payment, ' +
      'mobile money, e-load, e-pins, microinsurance',
  },
  {
    property: 'og:url',
    content: `https://${process.env.GATSBY_REACT_APP_HOSTNAME}/stories/FiveTypesOfAgents`,
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:title',
    content: '5 Types of Digipay Agents',
  },
  {
    property: 'og:description',
    content: 'Baka isa ka sa kanila, ka-Digipay!',
  },
  {
    property: 'og:image',
    content: Blog,
  },
]

const ReasonsWhy = () => (
  <Layout>
    <Helmet title="5 Types of Digipay Agents" meta={SITE_META}>
      <html lang="en" />
    </Helmet>

    <Row>
      <Column size="df:[100%] lg:[col-6]" py="sp4" px="sp3" m="auto">
        <Image width="100%" mb="sp3" src={Blog} />
        <Heading
          className="blog__title"
          mb="sp4"
          fontWeight="bold"
          textAlign="center"
        >
          5 TYPES OF DIGIPAY AGENTS
        </Heading>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            REFERRAL QUEEN
          </Heading>
          <Text>
            Kung sasali siya sa beauty pageant, siguradong Miss Congeniality ang
            award niya. Bakit? Siya lang naman ang may pinakamadaming friends
            para bigyan niya ng Digipay referral code. 100 credits agad ang
            mukukuha niya for every friend na magsa-sign up as Digipay Agent. Oh
            ‘diba, extra kita!
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            LOVE ON TOP-UP
          </Heading>
          <Text>
            Everybody sing. “🎶 Digipay it’s you! You’re the one I love! You’re
            the one I need! You’re the only one I see!🎶” Dahil love niya ang
            Digipay, kasing taas ng boses ni Beyonce ang top-up niya everyday.
            Paano ba naman kasi, sobrang dami niyang suki na pinili na lang
            mag-Digipay kesa pumila at ma-traffic. Kumita na, nakatulong pa sa
            buong barangay!
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            PASSIONISTA MOMMA
          </Heading>
          <Text>
            From the word itself, walang makakatalo sa passion niya everyday
            para magbigay serbisyo sa kanyang mga suki at kumita nang marami
            para sa kanyang pamilya. Siya ‘yung gumigising everyday with a smile
            para iwas bad vibes. May short term at long term goals siya para sa
            kanyang business kaya ganado humataw araw-araw!
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            MISS KNOW-IT-ALL
          </Heading>
          <Text>
            Wala ka pang sinasabi, alam na niya agad kung ano ang babayaran mo.
            Memorized na kasi niya ang transactions ng kanyang mga suki. She
            pays attention to every detail kaya alam niya ang pasikot-sikot sa
            Digipay app. At hindi lang ‘yan, updated siya sa announcements dahil
            lagi siyang nakaabang sa{' '}
            <a
              className="inline-link"
              href="https://www.facebook.com/groups/Digipayagents/"
            >
              {' '}
              Digipay Agent Group
            </a>
            . Panalo, ‘diba? I KNOW, RIGHT!
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            LADY GA-GALING
          </Heading>
          <Text>
            Dalawang bagay ang expertise ni Lady Ga-Galing. Una, magaling siyang
            makipag-usap sa customers at iniiwasan niyang sumabit sa kanila. Ang
            resulta? Malaki ang tiwala sa kanya. At pangalawa, magaling siyang
            magbenta dahil meron siyang sariling strategy. From bills payment,
            e-load, gaming pins, mobile money to microinsurance, alam niya ang
            pangangailangan ng kanyang mga suki!
          </Text>
        </Section>

        <Section mb="sp4">
          Sino ka sa kanila, ka-Digipay? Share your thoughts on our{' '}
          <a className="inline-link" href="https://www.facebook.com/digipayph/">
            {' '}
            Facebook page{' '}
          </a>{' '}
          and follow us on{' '}
          <a
            className="inline-link"
            href="https://www.instagram.com/digipayph/"
          >
            Instagram
          </a>
          !
        </Section>
      </Column>
    </Row>
  </Layout>
)

export default ReasonsWhy
